import { QuizFactory } from './quiz-factory';
import { IQuiz } from "./interfaces/quiz.interface";
import { ApiProvider } from './providers/api.provider';
import { appConfig } from './config/app.config';

declare global {
  interface Window {
    quizFactory: QuizFactory;
    dentoloUQuiz: IQuiz;
  }
}

const apiProvider = new ApiProvider(appConfig().api_host);
const quizFactory = new QuizFactory(apiProvider);
window.quizFactory = quizFactory;