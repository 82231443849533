import { AppConfig, appConfig } from "./config/app.config";
import { QuizEvent } from "./enums/quiz-event.enum";
import { EventEmitter } from "./components/event-emitter";
import { IFrame } from "./components/iframe";
import { PostMessageProvider } from "./providers/post-message.provider";
import { Quiz } from "./components/quiz";
import { QuizState } from "./components/quiz-state";
import { StoreProvider } from "./providers/store.provider";
import { StorageProvider } from "./providers/storage.provider";
import { optionsSchema } from './dto/options.schema';
import { IOptions } from "./interfaces/options.interface";
import Analytics from "./plugins/analytics";
import { AnalyticsType } from "./plugins/analytics/enums/analytics-type.enum";
import { IClientSetting } from './interfaces/client-setting.interface';
import { supplementObjectSoft } from './utils/supplement-object';
import { QuizMode } from "./enums/quiz-mode.enum";
import { IApiProvider } from './interfaces/api-provider.interface';

export class QuizFactory {
  constructor(private readonly apiProvider: IApiProvider) {}

  private VERSION = 3;

  public create(options: IOptions) {
    const parsedOptions = optionsSchema.parse(options);
    const config = appConfig<AppConfig>({ quiz_host: options.host });
    
    const quizEventEmitter = new EventEmitter<QuizEvent>();
    const storeProvider = new StoreProvider(new QuizState());
    const iframe = new IFrame(config.quiz_host, parsedOptions.mode, options.iframe);
    const postMessageProvider = new PostMessageProvider(iframe, config, storeProvider);
    const storageProvider = new StorageProvider();
  
    const quiz = new Quiz(
      parsedOptions,
      storeProvider,
      quizEventEmitter,
      postMessageProvider,
      storageProvider,
      iframe,
      this.apiProvider
    );

    const analyticsDatas = [ 
      ...(options.analytics || []), 
      { type: AnalyticsType.YA_METRIKA, id: config.ya_metrika_counter },
      { type: AnalyticsType.GA_3, id: config.gtag_id },
      { type: AnalyticsType.OWN_ANALYTICS, id: options.quizId }
    ]
  
    const analytics = new Analytics(analyticsDatas, this.apiProvider);
    quiz.use(analytics);
  
    window.dentoloUQuiz = quiz;
    return quiz;
  }

  public async applySettings(projectId: string) {
    const settings = await this.getSettings(projectId);
    console.log(settings);
  
    const quiz = quizFactory.create(settings.options);
    await quiz.init();

    if (settings.fixBlock && quiz.initialized()) quiz.addFixBlock(settings.fixBlockOptions);

    return quiz;
  }

  private async getSettings(projectId: string) {
    let options: IOptions;
    let setting: IClientSetting;

    let path = window.location.hostname + window.location.pathname;
    if (!path.endsWith("/") && !path.endsWith(".html") && !path.endsWith(".php")) path = path + "/";
    
    const settings = await this.apiProvider.getSettings(projectId, path, this.VERSION);
    
    if (settings.length == 0) throw new Error("Settings are not found");
    if (settings.length == 1) return settings[0];

    setting = settings.sort((item1, item2) => item2.priority - item1.priority)
      .reduce((prev, cur) => supplementObjectSoft(prev, cur));

    options = settings.sort((item1, item2) => item2.priority - item1.priority)
      .map(setting => setting.options)
      .reduce((prev, cur) => supplementObjectSoft(prev, cur));

    if (options.mode == QuizMode.FULL) setting.fixBlock = false;
    setting.options = options;

    return setting;
  }
}