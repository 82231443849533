import { AnalyticsEvent } from "./enums/analytics-event.enum";
import { IABTest } from "./interfaces/abtest.interface";
import { IAnalyticsBindEventData } from "./interfaces/analytics-data.interface";
import { IAnalyticsPlugin } from "./interfaces/analytics-plugin.interface";

declare global {
  interface Window {
    VK: any;
  }
}

export class VKPixel implements IAnalyticsPlugin {
  constructor(
    private readonly code: string, 
    private readonly bindEventsList?: IAnalyticsBindEventData[]
  ) {}
  
  init() {
    console.log("init");
    var $this = this;
    var t = document.createElement("script"); 
    t.type = "text/javascript", t.async = !0, 
    t.src = 'https://vk.com/js/api/openapi.js?169', 
    t.onload = function () { 
      window.VK.Retargeting.Init($this.code), 
      window.VK.Retargeting.Hit() 
    }, 
    document.head.appendChild(t); 
  }

  public setParams(params: Record<string, any>) {
  }

  public track(event: AnalyticsEvent, params?: Record<string, any>) {
    if (event == AnalyticsEvent.GET_LEAD) window.VK.Goal('lead');
    if (event == AnalyticsEvent.OPEN) window.VK.Goal('page_view');
  }

  public setABTest(abTest: IABTest) { }
}