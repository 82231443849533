import { IABTest } from "./interfaces/abtest.interface";
import { IAnalyticsPlugin } from "./interfaces/analytics-plugin.interface";
import { IAnalyticsBindEventData } from './interfaces/analytics-data.interface';
import { AnalyticsEvent } from "./enums/analytics-event.enum";

declare global {
  interface Window {
    ym: any;
    dentolo_quiz_ec: any[];
  }
}

export class YaMetrikaCounter implements IAnalyticsPlugin {
  constructor(
    private readonly metrika_number: number, 
    private readonly bindEventsList?: IAnalyticsBindEventData[],
    private readonly prefix: string = ""
  ) {}

  private ym: any;

  public init() {
    const SRC = "https://mc.yandex.ru/metrika/tag.js";
    const $this = this;

    this.ym = window.ym || function () {
      $this.ym.a = $this.ym.a || [];
      $this.ym.a.push(arguments);
    };

    this.ym.l = new Date().getTime();
    window.ym = this.ym;

    const isAlreadyExist = document.querySelector(`[src="${SRC}"]`);

    if (!isAlreadyExist) {
      const script = document.createElement("script");
      const firstScript = document.getElementsByTagName("script")[0];
      script.async = true;
      script.src = SRC;
      firstScript?.parentNode?.insertBefore(script, firstScript);
    }

    window.ym(this.metrika_number, "init", {
      trackLinks: true,
      clickmap:true,
      webvisor:true,
      accurateTrackBounce:true,
    });
  }

  public setParams(params: Record<string, any>) {
    this.ym(this.metrika_number, "params", params);
  }

  public track(event: AnalyticsEvent, params?: Record<string, any>) {
    let eventName: string = event;
    if (this.bindEventsList) {
      const customEventName = this.bindEventsList.find(item => item.event == event)?.value;
      eventName = customEventName || eventName;
    }

    eventName = this.prefix ? this.prefix + "_" + eventName : eventName;  
    
    if (event == AnalyticsEvent.REACH_QUESTION && params?.question) {
      eventName = `${eventName}_${params.question}`;
    }

    if (event == AnalyticsEvent.PICK_ANSWER && params?.answerIds) {
      eventName = `${eventName}_${params.questionName}_${(params.answerIds as Array<string | number>).join(",")}`;
      console.log(eventName);
    }

    this.ym(this.metrika_number, "reachGoal", eventName, params);
  }

  public setABTest(abTest: IABTest) {
    this.ym(this.metrika_number, "params", {
      ABTest: {
        [abTest.test_name]: abTest.test_variant
      }
    })
  }
}
