import { IApiProvider } from '../interfaces/api-provider.interface';
import { IClientSetting } from '../interfaces/client-setting.interface';
import { IVisitSchema } from '../plugins/analytics/dto/visit.schema';
import { IVisit } from '../plugins/analytics/enums/visit.interface';

export class ApiProvider implements IApiProvider {
  constructor(private readonly baseUrl: string) {}

  private request(url: string, method: string, body?: any): Promise<Response> {
    return fetch(this.baseUrl + url, { 
      method, 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      ...(body ? { body: JSON.stringify(body) } : {})
    });
  }

  public getSettings(projectId: string, path: string, version: number): Promise<IClientSetting[]> {
    return this.request(`/projects/${projectId}/client-settings?path=${path}&version=${version}`, "GET").then(response => {
      return response.json().then(data => data as IClientSetting[]);
    })
  }

  public isQuizAvailable(quizId: string): Promise<boolean> {
    return this.request(`/quizzes/${quizId}/availability`, "GET").then(response => {
      return response.json().then((data: { active: boolean }) => data.active);
    });
  }

  public createVisit(visitDto: IVisitSchema): Promise<IVisit> {
    return this.request(`/visits`, "POST", visitDto).then(response => {
      return response.json().then(data => data as IVisit);
    });
  }

  public updateVisit(id: string, visitDto: Partial<IVisitSchema>): Promise<boolean> {
    return this.request(`/visits/${id}`, "PATCH", visitDto).then(response => response.ok)
  }
}