export enum PostMessageEvent {
  SET_PLUGIN_DATA = "set_plugin_data",
  GET_INITIAL_DATA = "get_initial_data",
  GET_QUIZ = "get_quiz",
  REACH_GOAL = "reach_goal",
  SEND_ANALYTICS = "send_analytics",
  LOAD = "load",
  SET_AB_TEST = "set_ab_test",
  OPEN = "open",
  SEND_EXTRA_PARAMS = "set_extra_params",
  SEND_LEAD_EXTRA_PARAMS = "set_lead_extra_params",
  SET_HEADER_PHONE = "set_header_phone",
  ENABLE_WEBVISOR = "enable_webvisor",
  CLICK_CROSS = "click_cross",
  GET_LEAD = "get_lead",
  UPDATE_LEAD = "update_lead",
}