import { visitSchema, patrialVisitSchema } from './dto/visit.schema';
import { IVisit } from './enums/visit.interface';
import { AnalyticsEvent } from './enums/analytics-event.enum';
import { IAnalyticsPlugin } from './interfaces/analytics-plugin.interface';
import { getQueryParams } from '../../utils/get-query-params';
import { IApiProvider } from '../../interfaces/api-provider.interface';
import { IQuiz } from '../../interfaces/quiz.interface';
import UAParser from 'ua-parser-js';

export interface IUrlData {
  domain: string;
  href: string;
  path?: string;
  queries?: Record<string, string>;
}

export class OwnAnalytics implements IAnalyticsPlugin {
  constructor(
    private readonly quizId: string, 
    private readonly apiProvider: IApiProvider
  ) {}

  private visitId: string;
  private queue: Partial<IVisit>[] = [];
  private queueTimer: NodeJS.Timeout | undefined;
  private uaParser = new UAParser();

  private getUrlData() {
    let data: IUrlData = {
      domain: window.location.host,
      href: window.location.href,
      path: window.location.pathname,
      queries: getQueryParams(window.location.search.substring(1)),
    }

    return data;
  }

  async init(quiz: IQuiz) {
    const sessionKey = "dentolo_visit_id_for_" + this.quizId;
    let sessionId = window.sessionStorage.getItem(sessionKey);

    const uaData = this.uaParser.getResult();
    
    if (!sessionId) {
      const visitDto = visitSchema.parse({
        quizId: this.quizId,
        ...this.getUrlData(),
        browser_name: uaData.browser.name,
        browser_version: uaData.browser.version,
        os_name: uaData.os.name,
        os_version: uaData.os.version,
        device_model: uaData.device.model,
        device_brand: uaData.device.vendor,
        device_type: uaData.device.type
      });
      
      const visit = await this.apiProvider.createVisit(visitDto);
      
      this.visitId = visit.id;
      window.sessionStorage.setItem(sessionKey, this.visitId);
    } else {
      this.visitId = sessionId;
    }

    quiz.sendExtraParams({ visitId: this.visitId });
  }

  track(event: AnalyticsEvent, params?: Record<string, any>) {
    let eventName = "event_" + event;
    let param: Partial<IVisit> = {};

    if (event == AnalyticsEvent.REACH_QUESTION && params?.question) {
      eventName = eventName + "_" + params.question;
    }

    if (event == AnalyticsEvent.REACH_PHONE_FORM && params?.form) {
      param.form_type = params.form
    }

    if (event == AnalyticsEvent.OPEN && params?.trigger) {
      param.open_trigger = params.trigger
    }

    const visitDto = {
      [eventName]: true,
      ...param
    }

    this.updateVisit(visitDto);
  }
  
  private updateVisit(visitDto: Partial<IVisit>) {
    const parsedVisitDto = patrialVisitSchema.parse(visitDto);

    if (!Object.keys(parsedVisitDto).length) return;
    this.queue.push(parsedVisitDto);

    if (!this.queueTimer) {
      this.queueTimer = setInterval(() => {
        const visitDto = this.queue.shift();

        if (!visitDto) {
          if (this.queueTimer) clearInterval(this.queueTimer);
          this.queueTimer = undefined;
          return;
        }

        this.apiProvider.updateVisit(this.visitId, visitDto);
      }, 2000);
    }
  }

  setParams: (params: Record<string, any>) => void;
  setABTest: () => void;
}