import { z } from "zod";
import { BonusType } from "../enums/bonus-type.enum";

const imageSchema = z.object({
  url: z.string()
});

const bonusSchema = z.object({
  type: z.optional(z.nativeEnum(BonusType)),
  title: z.string(),
  image: z.optional(z.object({ url: z.string() })),
  imageStyle: z.optional(z.record(z.union([z.string(), z.number()]))),
  backgroundColor: z.optional(z.string())
});

const startPageSchema = z.object({
  offer: z.optional(z.string()),
  description: z.optional(z.string()),
  mainButtonText: z.optional(z.string()),
  minorButtonText: z.optional(z.string()),
  minorButtonUrl: z.optional(z.string()),
  visitsText: z.optional(z.string()),
  flyingButtonText: z.optional(z.string()),
  image: z.optional(imageSchema)
});

const headerSchema = z.object({
  phone: z.optional(z.string()),
  logo: z.optional(imageSchema),
  link: z.optional(z.string()),
  descriptor: z.optional(z.string())
});

const expertSchema = z.object({
  image: z.optional(imageSchema),
  header: z.string(),
  description: z.optional(z.string())
})

export const quizContentSchema = z.object({
  startPage: startPageSchema,
  header: headerSchema,
  bonuses: bonusSchema.array(),
  expert: expertSchema,
  footer: z.string(),
  finalForm: z.any(),
  signupForm: z.any(),
  finalPage: z.any()
}).partial();