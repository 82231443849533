import { QuizEvent } from "../enums/quiz-event.enum";
import { QuizStatus } from "../enums/quiz-status.enum";
import { IIframe } from "../interfaces/iframe.interface";
import { IQuiz } from "../interfaces/quiz.interface";
import { IStorageProvider } from "../interfaces/storage-provider.interface";
import { getQueryParams } from "../utils/get-query-params";
import { EventEmitter } from "./event-emitter";
import { PostMessageProvider } from "../providers/post-message.provider";
import { QuizState } from "./quiz-state";
import { IStoreProvider } from '../interfaces/store-provider.interface';
import { QuizMode } from '../enums/quiz-mode.enum';
import { OpenTrigger } from '../enums/open-trigger.enum';
import { getCookie } from "../utils/get-cookie";
import { IFixBlockOptions } from "../plugins/fix-block/interfaces/fix-block-options.interface";
import { IPostMessage } from '../interfaces/post-message.interface';
import { PostMessageEvent } from "../enums/post-message-event.enum";
import { IOptionsSchema } from '../dto/options.schema';
import { IPlugin } from "../interfaces/plugin.interface";
import { ILead } from "../interfaces/lead.interface";
import { IApiProvider } from '../interfaces/api-provider.interface';

export class Quiz implements IQuiz {
  constructor(
    private readonly options: IOptionsSchema,
    private readonly storeProvider: IStoreProvider<QuizState>,
    private readonly quizEventEmitter: EventEmitter<QuizEvent>,
    public readonly postMessageProvider: PostMessageProvider,
    private readonly storageProvider: IStorageProvider,
    private readonly iframe: IIframe,
    private readonly apiProvider: IApiProvider
  ) {}

  public async init(): Promise<void> {
    this.storeProvider.updateState({ status: QuizStatus.INITIALIZING });

    try {
      const available = await this.apiProvider.isQuizAvailable(this.options.quizId);
      if (!available) throw new Error(`Quiz ${this.options.quizId} is not available`);
    } catch (e) {
      console.error(e);
      this.storeProvider.updateState({ status: QuizStatus.NOT_AVAILABLE });
      return;
    }

    this.storeProvider.updateState({ leadExtraParams: this.getLeadExtraParams() });
    if (this.options.enableGeolocation) this.addGeolocation();

    this.postMessageProvider.subscribe(PostMessageEvent.LOAD, () => {
      this.storeProvider.updateState({ wasLoaded: true });
    });
    this.postMessageProvider.subscribe<ILead>(PostMessageEvent.GET_LEAD, message => this.quizEventEmitter.emit<ILead>(QuizEvent.GET_LEAD, message.data));

    await this.iframe.init();
    this.iframe.loadContent();
    this.sendDataToIframe();

    if (this.options.mode == QuizMode.CUSTOM) this.open(OpenTrigger.QUIZ_MODE_CUSTOM);

    if (this.options.mode == QuizMode.FULL) {
      this.open(OpenTrigger.QUIZ_MODE_FULL);
      this.addMeta();
    }
  
    const popupTimeout = this.storageProvider.getItem("popup");

    if (this.options.mode == QuizMode.POPUP) {
      if (this.options.autoOpen && !popupTimeout) this.autoOpen(this.options.autoOpen);
      this.postMessageProvider.subscribe<IPostMessage>(PostMessageEvent.CLICK_CROSS, () => this.close());
    }

    if (this.options.openOnScroll && !popupTimeout) {
      window.addEventListener("scroll", () => this.onScroll());
    }

    if (this.options.openOnLeave && !popupTimeout) {
      document.addEventListener("mouseleave", (e) => this.onLeave(e));
    }

    this.quizEventEmitter.emit(QuizEvent.INIT);
    this.storeProvider.updateState({ initialized: true, status: QuizStatus.CLOSED });
  }

  private getLeadExtraParams() {
    return {
      ...getQueryParams(window.location.search.substring(1)),
      ...this.options.leadExtraParams,
      href: window.location.href,
      domain: window.location.hostname,
      cookies: {
        _ga: getCookie("_ga"),
        _fbp: getCookie("_fbp"),
        _ym_uid: getCookie("_ym_uid"),
        roistat_visit: getCookie("roistat_visit"),
        roistat_first_visit: getCookie("roistat_first_visit"),
        roistat_visit_cookie_expire: getCookie("roistat_visit_cookie_expire"),
        _ct_session_id: getCookie("_ct_session_id")
      }
    }
  }

  private async sendDataToIframe() {
    this.postMessageProvider.send(PostMessageEvent.SET_PLUGIN_DATA, {
      quizId: this.options.quizId,
      mode: this.options.mode,
      domain: window.location.hostname,
      distributorAnswers: this.options.answerFilter,
      customContent: this.options.customContent,
      leadExtraParams: this.storeProvider.getState().leadExtraParams
    });
  }

  public getState() {
    return this.storeProvider.getState();
  }

  public initialized() {
    return this.storeProvider.getState().initialized;
  }

  public async open(trigger: OpenTrigger = OpenTrigger.OTHER, pageIndex = 0) {
    const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60 * this.options.autoOpenLimit);

    // Если плагин в процессе инициализации, то ожидаем
    if (this.storeProvider.getState().status == QuizStatus.INITIALIZING) {
      await new Promise<void>(resolve => this.quizEventEmitter.subscribe(QuizEvent.INIT, () => resolve()));
    }

    if (!this.storeProvider.getState().initialized) throw new Error("Необходима инициализация");

    if (this.storeProvider.getState().status == QuizStatus.OPENED) return;
    this.iframe.show();
    this.storageProvider.addItem("popup", true, expiryDate);

    await this.postMessageProvider.send(PostMessageEvent.OPEN, { trigger, pageIndex });
    this.storeProvider.updateState({
      status: QuizStatus.OPENED,
      wasOpened: true,
    });
    this.quizEventEmitter.emit(QuizEvent.OPEN);
  }

  public close() {
    if (this.storeProvider.getState().status == QuizStatus.OPENED) {
      this.storeProvider.updateState({
        status: QuizStatus.CLOSED,
        wasClosed: true,
      });
      this.iframe.hide();
      this.quizEventEmitter.emit(QuizEvent.CLOSE);
    }
  }

  public bind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.subscribe(event, callback);
  }

  public unbind(event: QuizEvent, callback: (e: any) => void) {
    return this.quizEventEmitter.unsubscribe(event, callback);
  }

  public bindPostMessageEvent<T extends {}>(event: PostMessageEvent, callback: (params: IPostMessage<T>) => void):void {
    this.postMessageProvider.subscribe<T>(event, callback);
  }

  public emitEvent<T>(event: QuizEvent, params: T) {
    this.quizEventEmitter.emit(event, params);
  }

  public setAutoOpen(seconds?: number) {
    const timer = seconds || this.options.autoOpen;
    if (timer) this.autoOpen(timer);
  }

  public sendLeadExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send(PostMessageEvent.SEND_LEAD_EXTRA_PARAMS, params);
  }

  public sendExtraParams(params: Record<string, any>) {
    return this.postMessageProvider.send(PostMessageEvent.SEND_EXTRA_PARAMS, params);
  }

  public setHeaderPhone(phone: string) {
    return this.postMessageProvider.send(PostMessageEvent.SET_HEADER_PHONE, { phone });
  }

  public enableWebvisor(counter: number) {
    return this.postMessageProvider.send(PostMessageEvent.ENABLE_WEBVISOR, { 
      counter, 
      domain: window.location.hostname 
    });
  }

  private autoOpen(seconds: number) {
    const { autoOpenTimeoutId } = this.storeProvider.getState();
    if (autoOpenTimeoutId) clearTimeout(autoOpenTimeoutId);

    const timeoutId = setTimeout(() => {
      const { wasOpened, status } = this.storeProvider.getState();
      if (!wasOpened && status == QuizStatus.CLOSED) {
        this.open(OpenTrigger.AUTO_OPEN);
      }
    }, seconds * 1000);

    this.storeProvider.updateState({
      autoOpenTimeoutId: timeoutId
    });
  }

  private onScroll(): void {
    const { pageYOffset, innerHeight } = window;

    if (this.storeProvider.getState().wasOpened) {
      return window.removeEventListener("scroll", this.onScroll);
    }

    if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) {
      this.open(OpenTrigger.SCROLL);
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  private onLeave(event: MouseEvent): void {
    if (this.storeProvider.getState().wasOpened) {
      return document.removeEventListener("mouseleave", this.onLeave);
    }

    if (event.clientY < 0) {
      this.open(OpenTrigger.LEAVE);
      document.removeEventListener("mouseleave", this.onLeave);
    }
  }

  private addMeta() {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }

  private async addGeolocation() {
    const Geolocation = (await import('../plugins/geolocation')).default;
    const geolocation = new Geolocation(this.storageProvider);
    this.use(geolocation);
  }

  public async addFixBlock(options: IFixBlockOptions = {}) {
    const FixBlock = (await import('../plugins/fix-block')).default;
    const fixblock = new FixBlock(options);
    return this.use(fixblock);
  }

  public use(plugin: IPlugin) {
    plugin.init(this);
    this.emitEvent(QuizEvent.LOAD_MODULE, plugin);
    return plugin;
  }
}